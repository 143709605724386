import styled from 'styled-components'
import { Link } from "gatsby"
import ListArrow from "../../images/BulletIcon.svg"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"

export const Section = styled.section`
	padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};
	background-image: ${props=>props.bgImg};
	background-color: ${props=>props.bgColor};	
	background-size:${props => props.bgSize};
	margin-top: ${props=>props.mt};
	height:${props=>props.ht};
	text-align: ${props=>props.textAlign};	
	border-bottom:1px solid ${props=>props.bdrBottom};
	border-width:${props=>props.bdrWidth};
	position: relative;	
	top: ${props=>props.top};
	&:before{
		content:'';
		display:${props => (props.bgBefore ? 'block' : 'none')};
		content:'';
		position:absolute;
		top:${props => props.topBefore};
		right:${props => props.rightBefore};
		bottom:${props => props.bottomBefore};
		left:${props => props.leftBefore};
		background:${props => props.bgBefore};
		background-size:${props => props.bgBeforeSize};
		background-repeat: ${props => props.bgBeforeRepeat};
		width:${props => props.bgBeforeWidth};
		height:${props => props.bgBeforeHeight};
		opacity:${props => props.opacityBefore};
		@media (max-width: 992px){
			width:${props => props.mbgBeforeWidth};
			height:${props => props.mbgBeforeHeight};
		}
	}
	&:after{
		content:'';
		display:${props => (props.bgAfter ? 'block' : 'none')};
		position:absolute;
		top:${props => props.topAfter};
		right:${props => props.rightAfter};
		bottom:${props => props.bottomAfter};
		left:${props => props.leftAfter};
		background:${props => props.bgAfter};
		background-size:${props => props.bgAfterSize};
		background-repeat: ${props => props.bgAfterRepeat};  
		width:${props => props.bgAfterWidth};
		height:${props => props.bgAfterHeight};
	}
	${BreakpointDown.xl`
		padding-top: ${props=>props.xpt};
		padding-bottom: ${props=>props.xpb};
		margin-top: ${props=>props.xmt};
		top: ${props=>props.xtop};		
	`}
	${BreakpointDown.md`
		padding-top: ${props=>props.mpt};
		padding-bottom: ${props=>props.mpb};
		top: ${props=>props.mtop};
	`}

	&.circle-left{
		position:relative;
		overflow:hidden;
		&:before{
			display: none;
			content: '';
			position: absolute;
			top: 50%;			
			transform: translateY(-50%);
			border-radius: 50%;			
			z-index: 0;
			overflow: hidden;
			width: 780px;
			height: 780px;
			left: -390px;
			border: 140px solid rgba(221,228,240,0.5);			
			${BreakpointUp.xl`
				display: block;
			`}			
		}
		&.overflow-intial{
			overflow: initial;
		}
		&.top-0{
			&:before{	
				top: 0;			
				transform: translateY(0);
			}
		}
		&.md{
			&:before{	
				width: 580px;
				height: 580px;
				left: -290px;
			}
		}
		&.sm{
			&:before{	
				width: 480px;
				height: 480px;
				left: -240px;
				border-width: 100px;
			}
		}
	}

	&.circle-right{
		position:relative;
		overflow:hidden;
		&:after{					
			display: none;
			content: '';
			position: absolute;
			top: 50%;			
			transform: translateY(-50%);
			border-radius: 50%;			
			z-index: 0;
			overflow: hidden;
			width: 780px;
			height: 780px;
			right: -390px;
			border: 140px solid rgba(221,228,240,0.5);
			${BreakpointUp.xl`
				display: block;
			`}
		}
		&.overflow-intial{
			overflow: inherit;
		}
		&.top-0{
			&:after{	
				top: 0;			
				transform: translateY(0);
			}
		}
		&.md{
			&:after{	
				width: 580px;
				height: 580px;
				right: -290px;
			}
		}
		&.sm{
			&:after{	
				width: 480px;
				height: 480px;
				right: -240px;
				border-width: 100px;
			}
		}
	}
`

Section.defaultProps = {
	top: "0",
	bgImg: "inherit",
	bgColor: "white",
	bgSize: "inherit",
	textAlign: "left",
	bdrBottom:"transparent",
	topAfter:"0",
	topBefore:"0",
	bdrWidth:"1px"
}

export const SectionPageTitle = styled.h1`
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	margin-right:auto;
	width:100%;
	position:relative;
	z-index:1;
	font-size: 28px;
	line-height: 38px;
	@media (min-width: 768px) {
		font-size: 38px;
		line-height: 48px;
	}
	@media (min-width: 992px) {
		font-size: 48px;
		line-height: 58px;
	}
	&:before{
		content: attr(data-shadowtext);
		position: absolute;
		bottom: 30%;
		color: rgba(0,0,0,0.04);
		font-weight: 900;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		display:none;
		@media (min-width: 768px) {
			font-size: 55px;
			line-height: 75px;
		}
		@media (min-width: 992px) {
			font-size: 65px;
			line-height: 85px;
		}
		@media (min-width: 1200px) {
			display:block;
			font-size: 70px;
			line-height: 100px
		}
	}	
`

SectionPageTitle.defaultProps = {
	textAlign: "center",
	color: "#000",
	maxWidth: "100%",
	mt: "auto",
	ml: "auto",
	mb: "10px"
}

export const SectionTitle = styled.h2`	
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	top:${props=>props.top};
	margin-right:auto;
	width:100%;
	position:relative;
	z-index:1;
	&:before{
		content: attr(data-shadowtext);
		position: absolute;
		bottom: 30%;
		color: ${props => props.colorBefore};
		font-weight: 900;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		display:none;
		@media (min-width: 768px) {
			font-size: 55px;
			line-height: 75px;
		}
		@media (min-width: 992px) {
			font-size: 65px;
			line-height: 85px;
		}
		@media (min-width: 1200px) {
			display:block;
			font-size: 70px;
			line-height: 100px
		}
	}

`

SectionTitle.defaultProps = {
	textAlign: "center",
	color: "#000",
	maxWidth: "100%",
	mt: "auto",
	ml: "auto",
	mb: "10px",
	colorBefore:"rgba(0,0,0,0.06)",
	top:"auto"
}
export const SectionShadowText = styled.span`		
	position: absolute;
	bottom: ${props => props.bottom};
	color: ${props => props.colorBefore};
	text-align: ${props=>props.textAlign};
	font-weight: 900;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	display:none;
	@media (min-width: 768px) {
		font-size: 55px;
		line-height: 75px;
	}
	@media (min-width: 992px) {
		font-size: 65px;
		line-height: 85px;
	}
	@media (min-width: 1200px) {
		display:block;
		font-size: 70px;
		line-height: 100px
	}

`

SectionShadowText.defaultProps = {	
	colorBefore:"rgba(0,0,0,0.06)",
	textAlign: "center",
	bottom: "30%",
	
}


export const SectionLead = styled.p`
	margin-left:${props=>props.ml};
	margin-right:${props=>props.mr};
	margin-bottom: ${props=>props.mb};	
	text-align: ${props=>props.textAlign};
	max-width:${props=>props.maxWidth};
	color: ${props=>props.color};
	width:100%;
	font-size: ${props=>props.fontSize};
	line-height:32px;
	@media (max-width: 767px) {
		font-size: 16px;
		line-height:30px;
	}
`
SectionLead.defaultProps = {
	fontSize: "18px",
	textAlign: "center",
	maxWidth: "100%",
	color: "#444",
	ml: "auto",
	mr: "auto",
	mb: "30px",
}

export const SectionDescription = styled.div`
	margin-left: ${props=>props.ml};
	margin-right: ${props=>props.mr};
	text-align: ${props=>props.textAlign};
	margin-bottom: ${props=>props.mb};
	max-width:${props=>props.maxWidth};
	color: ${props=>props.color};
	width:100%;
	& p{
		margin-bottom:0;
		font-size: 18px;
		line-height: 32px;
		@media (max-width: 767px) {
			font-size: 16px;
			line-height:30px;
		}
		+ p{
			margin-top:20px;
		}
	}
	
`
SectionDescription.defaultProps = {
	textAlign: "center",
	mb: "30px",
	ml: "auto",
	mr: "auto",
	maxWidth: "100%",
	color: "#444"
}


export const BreadCrumb = styled.section`	
	font-size: 12px;	
	line-height: 20px;
	position:relative;	
	z-index:2;
	top: ${props=>props.top};
	left: 0;
	text-align: ${props=>props.textAlign};
	${BreakpointUp.md`
		font-size: 16px;
		line-height: 24px;
	`}
	${BreakpointDown.xl`
		top: 100px;
	`}
	.container {
		display: flex;
		align-items: center;
		gap: 3px;
	}
	& a{
		color: #444;	
		display: inline-block;
		vertical-align: middle;		
		&:hover, &:focus{
			color: #000;		
		}
	}
	& span {
		color: #999;		
	}
	&.white{
		a{
			color: #fff;		
			&:hover, &:focus{
				color: #000;		
			}
		}
		& span {
			color:rgba(255,255,255,0.7);		
		}
	}
`
BreadCrumb.defaultProps = {
	top: "200px",
	textAlign:"left"
}


export const MainPanel = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
	align-items: ${props=>props.alignItems};
	@media(min-width: 992px){
		margin-right: -30px;
		margin-left: -30px;
	}
`
MainPanel.defaultProps = {
	alignItems: "inherit"
}
export const LeftPannel = styled.div`
	position:relative;
	width:100%;
	padding-left:15px;
	padding-right:15px;
	@media(min-width: 992px){
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
		padding-left:30px;
		padding-right:30px;
	}
`

export const RightPannel = styled.div`
	position:relative;	
	width:100%;
	padding-left:15px;
	padding-right:15px;
	@media(min-width: 992px){
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
		padding-left:30px;
		padding-right:30px;
	}
`

export const MarkdownContent = styled.div`
	display: ${props=>props.display};
	max-width:${props=>props.maxWidth};
	width:100%;
	margin:0 auto;
	padding-left:${props=>props.pl};
	padding-right:${props=>props.pr};
	& figure {
		margin-bottom:80px;
		position:relative;		
	}
	& h1{
		font-size: 28px;
		line-height: 38px;
		@media (min-width: 768px) {
			font-size: 38px;
			line-height: 48px;
		}
		@media (min-width: 992px) {
			font-size: 48px;
			line-height: 58px;
		}
	}
	& h2{
		font-size: 24px;
		line-height: 34px;
		@media (min-width: 768px) {
			font-size: 28px;
			line-height: 38px;
		}
		@media (min-width: 992px) {
			font-size: 32px;
			line-height: 42px;
		}
	}
	& h3{
		font-size: 20px;
		line-height: 30px;
		@media (min-width: 768px) {
			font-size: 24px;
			line-height: 34px;
		}
		@media (min-width: 992px) {
			font-size: 28px;
			line-height: 38px;
		}
	}
	& h4{
		font-size: 18px;
		line-height: 28px;
		margin-bottom:5px;
		@media (min-width: 768px) {
			font-size: 20px;
			line-height: 30px;
		}
		@media (min-width: 992px) {
			font-size: 24px;
			line-height: 34px;
		}
	}
	& strong {
		color:#000;
		font-size: 16px;
		line-height: 26px;
		@media (min-width: 992px) {
			font-size: 18px;
			line-height: 32px
		}
		+ p {
			margin-top:5px;
		}
	}
	& p {
		text-align: ${props=>props.textAlign};
		color: ${props=>props.color};
		margin-bottom:20px;
		font-size: 16px;
		line-height: 30px;
		> a{
			display:inline-block;
			&:hover, &:focus{
				text-decoration:underline;
			}
		}
	}
	& ul, & ol {
		list-style: none;
		margin:0 0 30px;
		padding:0;
		font-weight: 500;
		font-size: 16px;
		line-height: 30px;
		& li {
			padding-left:20px;
			vertical-align: text-top;
			margin:0;
			line-height: 26px;
			+ li{
				margin-top:15px;
			}
			&:before {
				content: url(${ListArrow});
				display: inline-block;
				margin-left: -20px;
				width: 20px;
				vertical-align: top;
			}
			> p{
				display:inline;
			}
		}
	}
	& ol {
		column-count: 1;
		column-gap: 5px;
		@media (min-width: 567px) {
			column-count: 2;
		}
		@media (min-width: 768px) {
			column-count: 3;
		}
	}
`

MarkdownContent.defaultProps = {
	textAlign: "left",
	maxWidth: "none",
	pl: "0",
	pr: "0",
	display: 'block',
}

export const BaseButton = styled.span`
	display: inline-flex;
	align-items: center;
	justify-content: center;	
	text-align:center;
	font-weight:700;
	position:relative;	
	border:1px solid transparent;	
	border-radius: 100px;
	transition: all ease 0.5s;
	padding:10px 20px;
	font-size:16px;
	overflow: hidden;
	&:before {
		border-radius: 100px;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background:transparent;
		opacity: 0;		
		transform: scale3d(0.7, 1, 1);		
		transition: transform 0.4s, opacity 0.4s;		
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}
	&:hover{
		&:before {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			background:transparent;
		}
	}
	${BreakpointUp.xl`
		padding:16px 40px;
		font-size:18px;
	`}
	> .text{
		line-height: 26px;
		display: inline-flex;
		align-items: center;
		position: relative;
		+ .icon{
			margin-left:10px;
		}
	}
	> .icon{
		height: 26px;		
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: relative;
		+ .text{
			margin-left:10px;
		}	
		&:empty {
			display: none;
		}
	}
`

export const ExploreMore = styled.div`
	display:block;
	text-align:center;
	margin-top:${props=>props.mt};
	margin-left:15px;
	margin-right:15px;
	margin-bottom:${props=>props.mb};
`
ExploreMore.defaultProps = {
	mt: "0px",
	mb: "0px"
}

export const FormControlLabel = styled.div`	
	&.active {
		font-size: 75%;
		transform: translate3d(0, -100%, 0);
		opacity: 1;
		color: #999;
	}
`
export const FormSubtitle = styled.div`	
	font-size:16px;
	line-height:30px;
	font-weight:bold;
	margin-bottom:30px;

`
export const CustomValidationMessage = styled.div`
	position: absolute;
	display: ${props=>props.display};
	top: ${props=>props.top};
	left: ${props=>props.left};
	width: ${props=>props.width};
	z-index: ${props=>props.zIndex};
	background: white;
	padding: 5px 10px;
	border-radius: 5px;
	margin-top: -15px;
	margin-left: 20px;
	color: #ED602B;
	font-size: 14px;
	font-weight: 600;
	box-shadow: 1px 2px 20px rgba(0,0, 0, 0.2);
`

CustomValidationMessage.defaultProps = {
	width: "160px",
	zIndex: 1
}

export const RequiredTriangle = styled.div`
	position: absolute;
	top: -7px;
	left: 20px;
	border-top: 15px solid white;
	border-right: 15px solid white;
	transform: rotate(-45deg);
`
export const Nodata = styled.div`
	background-color: #fff;
	padding: 30px;
	text-align: center;	
	position: relative;
    /* z-index: 2; */
	box-shadow: 0 0 5px rgba(0,0,0,0.05);
	&:before {
		content: "";
		position: absolute;
		top: 10px;
		right: 10px;
		bottom: 10px;
		left: 10px;
		border: 1px solid #dde4f0;
		${BreakpointUp.md`
			border: 4px solid #DDE4F0;
			top: 20px;
			right: 20px;
			bottom: 20px;
			left: 20px;
		`}
	}
	${BreakpointUp.md`
		padding:70px; 
		text-align: left;
	`}

	&.no-result{
		text-align:center;
		.nodata-figure{
			.gatsby-image-wrapper{
				display:inline-block !important;
				width:auto !important;
			}
		}
		.nodata-content{
			margin:0 auto;
		}
	}
`
export const NodataGrid = styled.div`
  position: relative;
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${BreakpointUp.lg`
        margin:0 -30px;
    `}
`
export const NodataGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
  ${BreakpointUp.lg`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding:0 30px;      
    `}
`
export const NodataGridRight = styled.div`
  width: 100%;
  position: relative;
  padding: 0 15px;
  max-width: ${props => props.maxWidth};
  ${BreakpointUp.md`
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
  ${BreakpointUp.lg`
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        padding:0 30px;      
    `}
`
export const NodataFigure = styled.div`
  margin-bottom: 20px;
  .gatsby-image-wrapper{
	width: initial;
	display: inline-block !important;
  }
`
export const NodataContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: ${props => props.maxWidth};
  p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 28px;
    ${BreakpointUp.lg`
        font-size: 18px;
        line-height: 30px;
    `}
  }
  .btn {
    flex-direction: row-reverse;
    .icon {
      + .text {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
`


export const SearchWrap = styled.div`
	position: relative;
`

export const SearchInput = styled.div`
  padding: 9px 25px;
  background-color: #fff;
  box-shadow: 0 8px 25px rgba(11, 97, 155, 0.1);
  width: 260px;
  height: 48px;
  box-sizing: border-box;
  z-index: 1;
  border: 1px solid #AFCFE5;
  border-radius: 100px;

  .input-icon {
	height: 30px;
  }

  .form-control {
	height: 30px;
  }
	.icon{
		svg{
			width:20px;
			height:20px;
			fill:#000;		
		}
	}
`

export const DropSearch = styled.div`
    position: absolute;
    background-color:#fff;
    box-shadow: 0px 2px 25px rgba(0,0,0,0.3);		
    width: 298px;
    top:100%;
    z-index:2;
    text-align: left;
    overflow-y: auto;
    max-height: 310px;
    border-radius:8px;

    &::-webkit-scrollbar {
      width: 4px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: #ebebeb;
    }
    &::-webkit-scrollbar-thumb {
      background: #6d6d6d; 
      border-radius:4px;
    }  
	
`
export const DropSearchBody = styled.div`
	padding:10px 0;
	
`
export const DropSearchItem = styled.div`
	padding:0 20px;
	border-bottom: 1px solid rgb(227, 233, 242);
	&:last-child{
    border-bottom:none;
	}
	& a {
		display: flex;
		align-items: center;
		color: #444;
		font-weight:500;		
		justify-content: space-between;
    padding:8px 0;			
    font-size:14px;
		line-height: 22px;

		& svg {
			fill:#444;
			transition: transform 0.3s ease-out;
			width:12px;
			display:none;
			${BreakpointUp.xl`
				display:block;
			`}
		}
		&:hover {			
			color: #000; 
			svg{				
				transform: translateX(4px);
				fill: #000; 				
			}  
		} 	
	}
`


export const PaginationNav = styled.nav`
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	
`
export const Pagination = styled.ul`
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	border-radius: 3px;
`
export const PageLink = styled(Link)`
	position: relative;
    display: block;
    padding: 5px 10px;
    margin-left: -1px;
    line-height: 1.25;
    color: #000;
    background-color: #fff;
    border: 1px solid #dee2e6;
	> .icon {
		color: #666;
	}
	&:hover {
		color: #fff;
		text-decoration: none;
		background-color: #FC0002;
		border-color: #dee2e6;
	}
	&:hover > .icon {
		color: #ffffff;
	}
	&:focus {
		z-index: 2;
		outline: 0;
		box-shadow: 0 0 0 0.2px rgba(190, 52, 49, 0.25);
	}
	&:focus > .icon {
		color: #ffffff;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
`
export const PageItem = styled.li`
	&.active .page-link {
		z-index: 1;
		color: #fff;
		background-color: #FC0002;
		border-color: #FC0002;
	}
	&.disabled .page-link {
		color: #777;
		pointer-events: none;
		cursor: auto;
		background-color: #ffffff;
		border-color: #ccc;
	}
`  