import React, {useContext} from "react";
import styled from 'styled-components'
import { CartIcon } from "../Icons";
import { Context } from "../context"

const CartNotifyIcon = styled.span`  
    position:relative;
    .icon{
        > svg{
            fill:#444;
            width: 32px;
            height: 32px;
        }
    }
    .count{
        position: absolute;
        top: -5px;
        right: -15px;
        background-color: #B30000;
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        line-height: 26px;
        font-weight: 700;
        width: 24px;
        height: 24px;
        text-align: center;
    }
`;

const CartButton = () => {
    const [cartData, setCartData] = useContext(Context);
    const cartItemCount = cartData?.length > 0 ? cartData.reduce(
        (acc, data) => (acc += data.count),
        0
    ) : 0;
    return(
        <CartNotifyIcon>
            <span className="icon"><CartIcon /></span>
            <span className="count">{cartItemCount}</span>
        </CartNotifyIcon>
    )
}
export default CartButton;