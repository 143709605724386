import React, { useState } from 'react'
import { navigate ,Link} from 'gatsby';
import { SearchWrap, SearchInput, DropSearch, DropSearchItem }  from "../../../components/Section"
import { SearchIcon }  from "../../../components/Icons"

const SearchBar = ({ data }) => {
	const [name, setName] = useState("");

    const handleSubmit = (evt) => {
        evt.preventDefault();
        navigate(`/search?term=${name}`);
    };
  return (
    <SearchWrap>
      <form onSubmit={handleSubmit}>
        <SearchInput className='search'>
          <div className="input-icon right">
            <input 
              className="form-control"
              type="text"
              id="headerSearchInput"
              value={name}
              placeholder="Search Your Building..."
              onChange={e => setName(e.target.value)}
              required
              aria-label="header search input"
            />
            <span className="icon"><SearchIcon /></span>
          </div>
        </SearchInput>
        
          <DropSearch className='drop-search'>        
            {

              data.filter(product => {
                if (name === '') {
                  return null;
                } else if (product.node.productName.toLowerCase().includes(name.toLowerCase())) {
                  return product.node.productName;
                } 
                  else if (product.node.productSku.toLowerCase().includes(name.toLowerCase())) {
                  return product.node.productSku;
                } 
              }).map((product, index) => (
                <DropSearchItem className='drop-search-item'  key={index}>
                  {
                    ( name === product.node.productSku ) ? 
                      <Link  to={product.node.url}>
                        {product.node.productSku}
                      </Link> 
                    :
                      <Link  to={product.node.url}>
                          {product.node.productName}
                      </Link>
                  }
                </DropSearchItem>
                )
              )

            }          
        </DropSearch>
    
      </form>
    </SearchWrap>
  )
}

export default SearchBar
