import React, { Component } from "react"
import styled, { keyframes } from "styled-components"
import { v4 } from "uuid"
import { Link, StaticQuery, graphql } from "gatsby"
import { DownArrowIcon } from "../Icons"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const StateWrap = styled.div`
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  padding: 20px 0;
  ${BreakpointUp.xl`
    padding:30px 0;    
  `}
`
const StateNavTabs = styled.div`
  display: flex;
  justify-content: space-between;
`
const StateNavItem = styled.div``
const StateContent = styled.div``
const StateTabPane = styled.div``
const DropButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &.isDesktop {
    display: none;
    ${BreakpointUp.md`   
      display: flex;
    `}
  }
  &.isMobile {
    display: flex;
    padding: 10px 0;
    ${BreakpointUp.md`   
    display:none;
    `}
  }
`
const DropButtonText = styled.div`
  margin-bottom: 0;
  width: calc(100% - 36px);
  display: inline-block;
  color: ${props => (props.isVisible ? "#0B619B" : "#000")};
  font-weight: 700;
`
const DropButtonIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${BreakpointUp.md`    
    width: 36px;
    height: 36px;
  `}
  & svg {
    float: right;
    transition: all 0.3s ease;
    transform: ${props => props.isVisible ? "rotate(180deg)" : "rotate(0deg)"};
    fill: ${props => (props.isVisible ? "#0B619B" : "#000")};
    ${BreakpointDown.md`    
      width:24px;
    `}
  }
  &:hover {
    & svg {
      fill: #0b619b;
    }
  }
`
const FadeContent = styled.div`
  display: ${props => (props.isVisible ? "block" : "none")};
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  position: relative;
  font-size: 14px;
  line-height: 26px;
  ${BreakpointUp.md`    
    font-size: 16px;
    line-height: 28px;
  `}
`
const StateMenulist = styled.div`
  margin: 15px -15px;
  list-style-type: none;
  column-count: 2;
  ${BreakpointUp.sm`    
    column-count: 4;     
  `}
`
const StateMenuItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 5px 15px;
  margin-bottom: 0;
  a {
    color: #999;
    &:hover {
      color: #000;
    }
  }
`

class FadeDrop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMetalVisible: false,
      isCarportVisible: false,
      isGarageVisible: false,
      isRVVisible: false,
    }
    this.handleMetalFade = this.handleMetalFade.bind(this)
    this.handleCarportFade = this.handleCarportFade.bind(this)
    this.handleGarageFade = this.handleGarageFade.bind(this)
    this.handleRVFade = this.handleRVFade.bind(this)
  }
  handleMetalFade() {
    const nextState = !this.state.isMetalVisible
    this.setState({
      isMetalVisible: nextState,
      isCarportVisible: false,
      isGarageVisible: false,
      isRVVisible: false,
    })
  }
  handleCarportFade() {
    const nextState = !this.state.isCarportVisible
    this.setState({
      isMetalVisible: false,
      isCarportVisible: nextState,
      isGarageVisible: false,
      isRVVisible: false,
    })
  }
  handleGarageFade() {
    const nextState = !this.state.isGarageVisible
    this.setState({
      isMetalVisible: false,
      isCarportVisible: false,
      isGarageVisible: nextState,
      isRVVisible: false,
    })
  }
  handleRVFade() {
    const nextState = !this.state.isRVVisible
    this.setState({
      isMetalVisible: false,
      isCarportVisible: false,
      isGarageVisible: false,
      isRVVisible: nextState,
    })
  }
  render() {
    const {
      isMetalVisible,
      isCarportVisible,
      isGarageVisible,
      isRVVisible,
    } = this.state
    return (
      <StaticQuery
        query={graphql`
          query FooterStatesQuery {
            allContentfulState(sort: { order: ASC, fields: name }) {
              nodes {
                name
                abbreviation
              }
            }
          }
        `}
        render={data => (
          <StateWrap>
            <StateNavTabs>
              <StateNavItem>
                <DropButton
                  className="isDesktop"
                  onClick={this.handleMetalFade}
                  isMetalVisible={isMetalVisible}
                >
                  <DropButtonText isVisible={isMetalVisible}>
                    Metal Buildings
                  </DropButtonText>
                  <DropButtonIcon isVisible={isMetalVisible}>
                    <DownArrowIcon />
                  </DropButtonIcon>
                </DropButton>
              </StateNavItem>
              <StateNavItem>
                <DropButton
                  className="isDesktop"
                  onClick={this.handleCarportFade}
                  isCarportVisible={isCarportVisible}
                >
                  <DropButtonText isVisible={isCarportVisible}>
                    Metal Carports
                  </DropButtonText>
                  <DropButtonIcon isVisible={isCarportVisible}>
                    <DownArrowIcon />
                  </DropButtonIcon>
                </DropButton>
              </StateNavItem>
              <StateNavItem>
                <DropButton
                  className="isDesktop"
                  onClick={this.handleGarageFade}
                  isGarageVisible={isGarageVisible}
                >
                  <DropButtonText isVisible={isGarageVisible}>
                    Metal Garages
                  </DropButtonText>
                  <DropButtonIcon isVisible={isGarageVisible}>
                    <DownArrowIcon />
                  </DropButtonIcon>
                </DropButton>
              </StateNavItem>
              <StateNavItem>
                <DropButton
                  className="isDesktop"
                  onClick={this.handleRVFade}
                  isRVVisible={isRVVisible}
                >
                  <DropButtonText isVisible={isRVVisible}>
                    RV Carports
                  </DropButtonText>
                  <DropButtonIcon isVisible={isRVVisible}>
                    <DownArrowIcon />
                  </DropButtonIcon>
                </DropButton>
              </StateNavItem>
            </StateNavTabs>
            <StateContent>
              <StateTabPane>
                <DropButton
                  className="isMobile"
                  onClick={this.handleMetalFade}
                  isMetalVisible={isMetalVisible}
                >
                  <DropButtonText isVisible={isMetalVisible}>
                    Metal Buildings
                  </DropButtonText>
                  <DropButtonIcon isVisible={isMetalVisible}>
                    <DownArrowIcon />
                  </DropButtonIcon>
                </DropButton>
                <FadeContent isVisible={isMetalVisible}>
                  <StateMenulist>
                    {data.allContentfulState.nodes.map(state => {
                      return (
                        <StateMenuItem key={v4()}>
                          <Link
                            to={`/metal-buildings-${state.name
                              .split(" ")
                              .join("-")
                              .toLowerCase()}-${state.abbreviation.toLowerCase()}`}
                          >
                            {state.name}
                          </Link>
                        </StateMenuItem>
                      )
                    })}
                  </StateMenulist>
                </FadeContent>
              </StateTabPane>
              <StateTabPane>
                <DropButton
                  className="isMobile"
                  onClick={this.handleCarportFade}
                  isCarportVisible={isCarportVisible}
                >
                  <DropButtonText isVisible={isCarportVisible}>
                    Metal Carports
                  </DropButtonText>
                  <DropButtonIcon isVisible={isCarportVisible}>
                    <DownArrowIcon />
                  </DropButtonIcon>
                </DropButton>
                <FadeContent isVisible={isCarportVisible}>
                  <StateMenulist>
                    {data.allContentfulState.nodes.map(state => {
                      return (
                        <StateMenuItem key={v4()}>
                          <Link
                            to={`/metal-carports-${state.name
                              .split(" ")
                              .join("-")
                              .toLowerCase()}-${state.abbreviation.toLowerCase()}`}
                          >
                            {state.name}
                          </Link>
                        </StateMenuItem>
                      )
                    })}
                  </StateMenulist>
                </FadeContent>
              </StateTabPane>

              <StateTabPane>
                <DropButton
                  className="isMobile"
                  onClick={this.handleGarageFade}
                  isGarageVisible={isGarageVisible}
                >
                  <DropButtonText isVisible={isGarageVisible}>
                    Metal Garages
                  </DropButtonText>
                  <DropButtonIcon isVisible={isGarageVisible}>
                    <DownArrowIcon />
                  </DropButtonIcon>
                </DropButton>
                <FadeContent isVisible={isGarageVisible}>
                  <StateMenulist>
                    {data.allContentfulState.nodes.map(state => {
                      return (
                        <StateMenuItem key={v4()}>
                          <Link
                            to={`/metal-garages-${state.name
                              .split(" ")
                              .join("-")
                              .toLowerCase()}-${state.abbreviation.toLowerCase()}`}
                          >
                            {state.name}
                          </Link>
                        </StateMenuItem>
                      )
                    })}
                  </StateMenulist>
                </FadeContent>
              </StateTabPane>

              <StateTabPane>
                <DropButton
                  className="isMobile"
                  onClick={this.handleRVFade}
                  isRVVisible={isRVVisible}
                >
                  <DropButtonText isVisible={isRVVisible}>
                    RV Carports
                  </DropButtonText>
                  <DropButtonIcon isVisible={isRVVisible}>
                    <DownArrowIcon />
                  </DropButtonIcon>
                </DropButton>
                <FadeContent isVisible={isRVVisible}>
                  <StateMenulist>
                    {data.allContentfulState.nodes.map(state => {
                      return (
                        <StateMenuItem key={v4()}>
                          <Link
                            to={`/metal-rv-covers-${state.name
                              .split(" ")
                              .join("-")
                              .toLowerCase()}-${state.abbreviation.toLowerCase()}`}
                          >
                            {state.name}
                          </Link>
                        </StateMenuItem>
                      )
                    })}
                  </StateMenulist>
                </FadeContent>
              </StateTabPane>
            </StateContent>
          </StateWrap>
        )}
      />
    )
  }
}
export default FadeDrop
