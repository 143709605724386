import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryBtn = styled(BaseButton)`		
	background-color: #B30000;
	border-color: #B30000;
	color: #fff;	
	& .icon{
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		background-color: transparent;
		border-color: #0B619B;
		color: #fff;
		&:before {
			background: #0B619B;
		}
	}
`
const PrimaryButton = (props) => {
	const { icon, text, textBefore } = props;
	return(
		<PrimaryBtn className='btn'>
			{textBefore ?
                (
                    <>
                    <span className='text'>{textBefore}</span>
                    <span className='icon'>{icon}</span>
                    </>
                ):(
                    <>
                    <span className='icon'>{icon}</span>
                    <span className='text'>{text}</span>
                    </>
                )
            }
		</PrimaryBtn>
	)
}


export default PrimaryButton