/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useState, useEffect, Suspense} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../scss/layout.css"
import Footer from "./Footer"
import Ads from './ADS'
import { Context } from "./context";

const SaleBlackFriday = React.lazy(() =>
  import("./SaleBlackFriday")
)

function getInitialState() {
  if (typeof window !== `undefined`) {
    const cartData = localStorage.getItem('cartData');
    return cartData ? JSON.parse(cartData) : [];
  }
}

const Layout = ({
  children,
  location,
  productURL,
  noHeader,
  noFooter,
  noSticky,
  noADS,
  noFooterForm = false
}) => {
  const isSSR = typeof window === "undefined"
  const [cartData, setCartData] = useState(getInitialState);

  useEffect(() => {
    localStorage.setItem('cartData', JSON.stringify(cartData))
  }, [cartData, setCartData]);

  useEffect(() => {
    document.head.insertAdjacentHTML(
      "beforeend",
      `<style id="chat-widget-hide-style">#chat-widget-container { display: none !important;}</style>`
    );
    const chatWidgetElement = document.getElementById("chat-widget-hide-style");
    window.addEventListener('scroll', function(){
      
      if (window.pageYOffset > 800 && chatWidgetElement) {
        chatWidgetElement.remove();
      }
    });
  }, []);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulProduct {
        edges {
          node {
            productSku
            productName
            url
            width
            height
            length
          }
        }
      }
    }
  `)

 
  
  return (
    <Context.Provider value={[cartData, setCartData]}>
      { !noHeader && <Header siteTitle={data.site.siteMetadata?.title || `Title`}
       productData = {data.allContentfulProduct.edges} 
       location ={location} /> }
      { !noADS ?  <main>{children} <Ads /></main> : <>{children}</> }
      { !noFooter &&  <Footer location={location} noFooterForm={noFooterForm} /> }
      { !isSSR && (
        <Suspense
          fallback=""
        >
        {(location.pathname !== productURL ) &&  !noSticky && (
          <SaleBlackFriday location={location} />
        )}
        </Suspense>
      )}
    </Context.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout